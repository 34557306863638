<template>
  <v-container class="pa-0" fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-2">
          <!-- <v-card-title>
            <v-container fluid>
              <v-toolbar flat>
                <v-toolbar-title>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <h2 class="textPrimary">
                      Dashboard
                    </h2>
                  </v-col>
                </v-toolbar-title>
                <v-spacer />
                <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="search"
                    class="mt-6"
                    prepend-icon="mdi-magnify"
                    label="Buscar"
                    dense
                    hide-details
                  />
                </v-col>
              </v-toolbar>
            </v-container>
          </v-card-title> -->
          <template>
            <v-card flat>
              <v-toolbar color="primary" dark extended flat>
                <v-row>
                  <v-col cols="11" class="text-center mt-12">
                    <h2>ANUNCIOS NUEVOS</h2>
                  </v-col>
                  <v-col cols="1" class="mt-12">
                    <v-spacer />
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-col>
                </v-row>
              </v-toolbar>
            </v-card>
            <br />
          </template>
          <v-container fluid>
            <v-row>
              <v-col
                v-for="(item, index) in 3"
                :key="index"
                cols="12"
                md="4"
                sm="12"
              >
                <v-card width="400">
                  <v-row>
                    <v-col cols="10" />
                    <v-col cols="2">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-img
                          width="100"
                          src="../../assets/icons/person.svg"
                        />
                      </v-col>
                      <v-col cols="6">
                        <br />
                        <h2 style="color: #479b25">3 socios nuevos</h2>
                        <br />
                        <v-btn height="40" small color="primary" dark>
                          Validar datos
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <template>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" md="4" sm="12" lg="4">
                <h2>Indicador uno</h2>
                <v-card>
                  <div
                    style="background: #479b25; width: 10px; height: 100px"
                  />
                </v-card>
                <br />
                <v-card width="100%">
                  <div style="background: yellow; width: 10px; height: 100px" />
                </v-card>
              </v-col>
              <v-col cols="8">
                <h2>Indicador dos</h2>
                <v-card width="100%" height="225" />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      search: "",
    };
  },
};
</script>
